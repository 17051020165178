<template>
    <div class="basic_data_edit">
        <div
            class="yzjyPart"
            :style="{ paddingBottom: !yjczzdjyState ? '10px' : '' }"
        >
            <div class="titlePart flex-y-center">
                <div class="sideBar"></div>
                <div class="title">请填写预警处置指导建议</div>
            </div>
            <div class="inputPart">
                <el-input
                    v-model="gridItem.JY"
                    placeholder="请填写指导建议"
                    type="textarea"
                    :rows="3"
                    :disabled="!yjczzdjyState"
                ></el-input>
            </div>
            <div
                class="btn pointer flex-both-center"
                @click="zdjySubmit()"
                v-if="yjczzdjyState"
            >
                保存
            </div>
        </div>
        <div class="data_content dataCtnForm scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <div class="tablePart">
                <!-- 预警信息 + 跟踪记录 -->
                <div class="tableLeft">
                    <!-- 预警信息 -->
                    <div class="yjInfoPart">
                        <div class="title">预警信息</div>
                        <div class="yjxxCtn">
                            <div class="img">
                                <el-image
                                    :src="yjxxImgUrl"
                                    fit="cover"
                                    class="pointer"
                                    v-viewer
                                    style="width: 100%; height: 100%"
                                >
                                    <div
                                        slot="error"
                                        style="width: 100%; height: 100%"
                                    >
                                        <SnNoData
                                            typeNum="1"
                                            width="150px"
                                        ></SnNoData>
                                    </div>
                                </el-image>
                            </div>
                            <div class="yjxxDesc">
                                <div class="yjxxInfoItem flex">
                                    <div class="label">名称：</div>
                                    <div :title="yjInfo.YJSJ">
                                        {{ yjInfo.YJSJ }}
                                    </div>
                                </div>
                                <div class="yjxxInfoItem flex">
                                    <div class="label">预警等级：</div>
                                    <div
                                        class="yjdj"
                                        :style="{
                                            color: colorArr[
                                                Number(yjInfo.YJDJ)
                                            ],
                                            borderColor:
                                                colorArr[Number(yjInfo.YJDJ)],
                                        }"
                                    >
                                        {{ yjInfo.YJDJNAME }}
                                    </div>
                                </div>
                                <div class="yjxxInfoItem flex">
                                    <div class="label">分类：</div>
                                    <div>{{ yjInfo.YJDXMC }}</div>
                                </div>
                                <!-- <div class="yjxxInfoItem flex">
                                    <div class="label">来源：</div>
                                    <div>{{ yjInfo.LYNAME }}</div>
                                </div> -->
                                <div class="yjxxInfoItem flex">
                                    <div class="label">时间：</div>
                                    <div>{{ yjInfo.FSYJSJ }}</div>
                                </div>
                                <div class="yjxxInfoItem flex">
                                    <div class="label">描述：</div>
                                    <div :title="yjInfo.YJSM">
                                        {{ yjInfo.YJSM }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 预警跟踪 -->
                    <div class="left-bottom">
                        <div class="title">预警跟踪</div>
                        <el-form
                            ref="formYjgz"
                            class="rwxf-form"
                            :model="yjgzpzData"
                            :rules="ruleYjgz"
                            label-width="100px"
                            size="mini"
                        >
                            <el-form-item
                                v-if="gridItem && gridItem.YJDXMC == '日常管理'"
                                label="选择基准照"
                                prop="YDDSYT"
                            >
                                <div
                                    class="flex flex-wrap scrollbar"
                                    style="height: 86px"
                                >
                                    <div
                                        v-for="(item, index) in basePicList"
                                        :key="index"
                                        style="
                                            margin-right: 4px;
                                            margin-bottom: 4px;
                                            position: relative;
                                        "
                                        class="pointer"
                                        @click="getBasePicItem(item)"
                                    >
                                        <img
                                            v-if="
                                                yjgzpzData.YDDSYT ===
                                                item.CCLJEX
                                            "
                                            style="
                                                position: absolute;
                                                right: 0;
                                                top: 0;
                                            "
                                            src="@image/icon/triangle_pass.png"
                                        />
                                        <img
                                            :src="item.CCLJ"
                                            style="width: 80px; height: 80px"
                                        />
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="跟踪负责人" prop="">
                                <SnTreeCjr
                                    @handleChange="getCjrItem"
                                    ref="SnTreeCjr"
                                ></SnTreeCjr>

                                <!-- :disabled="yjgzpzData.SFYRW === 1" -->
                                <!-- <el-select
                                    v-model="yjgzpzData.FZRID"
                                    filterable
                                    placeholder="请选择跟踪负责人"
                                    style="width: 400px"
                                >
                                    <el-option
                                        v-for="(item, index) in gzfzrList"
                                        :key="index + item.ID"
                                        :label="item.REALNAME"
                                        :value="item.ID"
                                    ></el-option>
                                </el-select> -->
                            </el-form-item>
                            <div class="flex">
                                <el-form-item label="跟踪周期" prop="ZQ">
                                    <!--  :disabled="yjgzpzData.SFYRW === 1" -->
                                    <el-input
                                        v-model="yjgzpzData.ZQ"
                                        placeholder="请填写跟踪周期"
                                        style="width: 300px"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label=""
                                    prop=""
                                    style="position: relative; left: -80px"
                                >
                                    <!-- :disabled="yjgzpzData.SFYRW === 1" -->
                                    <el-select
                                        v-model="yjgzpzData.ZQDW"
                                        style="width: 80px"
                                    >
                                        <el-option
                                            v-for="(item, index) in zqdwList"
                                            :key="index"
                                            :label="item"
                                            :value="item"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <el-form-item label="开始时间" prop="KSSJ">
                                <!-- :disabled="yjgzpzData.SFYRW === 1" -->
                                <el-date-picker
                                    v-model="yjgzpzData.KSSJ"
                                    type="date"
                                    placeholder="请选择开始时间"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    style="width: 400px"
                                ></el-date-picker>
                                <!-- v-show="yjgzpzData.SFYRW !== 1" -->
                                <el-button
                                    type="primary"
                                    style="margin-left: 20px"
                                    @click="yjgzSendTask()"
                                    >下发任务</el-button
                                >
                            </el-form-item>
                        </el-form>
                        <!-- 预警跟踪记录 -->
                        <div v-if="yjgzList.length > 0" class="yjgzCtn">
                            <div class="yjgzLeft">
                                <div class="timeList scrollbar-hidden">
                                    <!-- 时间列表 -->
                                    <div
                                        v-for="(item, index) in yjgzList"
                                        :key="index"
                                        class="timeItem"
                                        :class="[
                                            index == timeActiveNum
                                                ? 'timeItemAvtive'
                                                : 'timeItemDefault',
                                        ]"
                                        @click="changeTime(item, index)"
                                    >
                                        {{ item.time }}
                                    </div>
                                </div>
                                <div class="axis-line"></div>
                            </div>
                            <div class="yjgz-right">
                                <div class="yjgz-img">
                                    <el-image
                                        :src="yjgzDetail.XCZP"
                                        fit="cover"
                                        class="pointer"
                                        v-viewer
                                        style="width: 100%; height: 100%"
                                    >
                                        <div
                                            slot="error"
                                            style="width: 100%; height: 100%"
                                        >
                                            <SnNoData typeNum="1"></SnNoData>
                                        </div>
                                    </el-image>
                                </div>
                                <div class="yjgz-info">
                                    <span>情况描述：</span>
                                    <span>{{ yjgzDetail.XCMS }}</span>
                                </div>
                                <div class="yjgz-info">
                                    <span>巡查员：</span>
                                    <span>{{ yjgzDetail.CJRMC }}</span>
                                </div>
                                <div
                                    class="sfjc-border"
                                    :style="{
                                        color: yjgzDetail.color,
                                        borderColor: yjgzDetail.color,
                                    }"
                                >
                                    {{ yjgzDetail.SFJCMC }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 预警处置 -->
                <div class="tableRight">
                    <div class="title">预警处置</div>
                    <div class="yjcz-steps">
                        <div
                            v-for="(item, index) in yjczSteps"
                            :key="index"
                            class="flex"
                        >
                            <div
                                class="steps-label"
                                :style="{
                                    background: getTitleBg(item),
                                    cursor: getMouse(item),
                                }"
                                @click="handleYjczStepClick(item.ID)"
                            >
                                {{ item.LABEL }}
                            </div>
                            <div
                                class="steps-line"
                                v-if="index < yjczSteps.length - 1"
                            ></div>
                        </div>
                    </div>
                    <!-- 0.预警核实 -->
                    <div v-if="currentStep === 0" class="yjhs-border">
                        <Yjhs
                            :yjztNum="yjztNum"
                            :ztNum="ztNum"
                            @changeTopState="changeTopState"
                        ></Yjhs>
                    </div>
                    <!-- 1.预警分发 -->
                    <div v-if="currentStep === 1" class="yjsb-border">
                        <Yjsb
                            :yjztNum="yjztNum"
                            :ztNum="ztNum"
                            :gzfzrList="gzfzrList"
                            @changeTopState="changeTopState"
                        ></Yjsb>
                    </div>
                    <!-- 2.处置结果反馈 -->
                    <div v-if="currentStep === 2" class="yjsb-border">
                        <Jgfk
                            :yjztNum="yjztNum"
                            :ztNum="ztNum"
                            :gzfzrList="gzfzrList"
                            @changeTopState="changeTopState"
                        ></Jgfk>
                    </div>
                    <!-- 3.处置跟踪 -->
                    <div v-if="currentStep === 3" class="yjsb-border">
                        <Czgz
                            :yjztNum="yjztNum"
                            :ztNum="ztNum"
                            @changeTopState="changeTopState"
                        ></Czgz>
                    </div>
                    <!-- 4.处置工作完成 -->
                    <div v-if="currentStep === 4" class="yjhs-border">
                        <Czgzwc
                            :yjztNum="yjztNum"
                            :ztNum="ztNum"
                            :yjInfo="yjInfo"
                            @changeTopLast="getYjInfo"
                        ></Czgzwc>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import snButton from "./comp/edit-button.js";
import infoMixin from "./comp/info_mixin.js";
import { mapGetters, mapActions } from "vuex";
import Yjhs from "./comp/yjcz/1_yjhs.vue"; //预警核实
import Yjsb from "./comp/yjcz/2_yjsb.vue"; //预警分发
import Jgfk from "./comp/yjcz/3_jgfk.vue"; //结果反馈
import Czgz from "./comp/yjcz/4_czgz.vue"; //处置跟踪
import Czgzwc from "./comp/yjcz/5_czgzwc.vue"; //处置工作完成
import Draggabilly from "draggabilly";
export default {
    name: "",
    components: {
        Yjhs,
        Yjsb,
        Jgfk,
        Czgz,
        Czgzwc,
    },
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            gridItem: {
                CJSJID: "",
                FSYJSJ: "",
                ID: "",
                JD: "",
                JY: null,
                SFFSDX: null,
                SFYXYJ: "",
                SJLX: "",
                WD: "",
                WJLJ: "",
                YAMC: null,
                YCYSBM: "",
                YJDJ: "",
                YJDJNAME: "",
                YJDX: "",
                YJDXMC: "",
                YJPZID: "",
                YJSJ: "",
                YJSM: "",
                YJZT: "",
                YJZTNAME: "",
                ZT: "",
            }, //表单 某一项
            yjxxImgUrl: "", //预警信息 左上图片
            yjInfo: {
                YJSJ: "", //预警事件
                YJDJ: "", //预警等级
                YJDJNAME: "", //预警等级 名称
                YJDXMC: "", //类型
                LYNAME: "", //来源
                FSYJSJ: "", //时间
                YJSM: "", //描述
            },
            colorArr: ["#ff0000", "#ff6600", "#ffcc00", "#0099ff"],
            gzfzrList: [], //人员列表
            basePicList: [], //基准照列表
            // 预警跟踪
            yjgzpzData: {
                ID: "",
                YDDSYT: "", //日常管理下 选择的基准照片
                FZRID: "", //跟踪负责人
                ZQ: "", //跟踪周期
                ZQDW: "周", //跟踪周期 单位
                KSSJ: "", //开始时间
                SFYRW: 0, //是否有任务；0没有，1有
            },
            ruleYjgz: {
                YDDSYT: [
                    {
                        required: true,
                        message: "请选择基准照片",
                        trigger: "blur",
                    },
                ],
                FZRID: [
                    {
                        required: true,
                        message: "请输入跟踪负责人",
                        trigger: "blur",
                    },
                ],
                ZQ: [
                    {
                        required: true,
                        message: "请输入周期",
                        trigger: "blur",
                    },
                ],
                KSSJ: [
                    {
                        required: true,
                        message: "请选择开始时间",
                        trigger: "blur",
                    },
                ],
            },
            zqdwList: ["周", "日"],
            yjgzList: [], //预警跟踪记录
            yjgzDetail: {
                XCMS: "", //情况描述
                CJRMC: "", //巡查员
                SFJC: "", //是否接触
                XCZP: "", //现场照片
            },

            // 预警处置
            yjztNum: -1, // 预警状态
            ztNum: -1, // 预警步骤
            currentStep: -1, // 当前点击的步骤，内容板块可见性
            yjczSteps: [
                {
                    ID: 0,
                    LABEL: "预警核实",
                },
                {
                    ID: 1,
                    LABEL: "预警分发",
                },
                {
                    ID: 2,
                    LABEL: "结果反馈",
                },
                {
                    ID: 3,
                    LABEL: "处置跟踪",
                },
                {
                    ID: 4,
                    LABEL: "处置工作完成",
                },
            ],
            timeActiveNum: 0, //左侧时间点击
            yjczzdjyState: false, //预警处置指导建议
        };
    },
    created() {},
    computed: {
        ...mapGetters(["system"]),
    },
    mounted() {
        // 只有茶特中心和超级管理员 可以保存数据
        if (
            this.system == "system3" ||
            localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY)
        ) {
            this.yjczzdjyState = true;
        }

        this.gridItem = JSON.parse(this.$route.query.data);
        let elem = document.querySelector(".yzjyPart");
        let draggie = new Draggabilly(elem, {
            containment: true,
            handle: "",
        });
        this.snButton.buttonData[1].isShow = false;
        this.snButton.buttonData[0].isShow = false;
        this.getYjInfo(); //预警信息
        this.getUserList(); //用户列表
        this.getYjgzpzData(); //预警跟踪 下发任务
        this.getYjgzjlData(); //预警跟踪记录数据
        if (this.gridItem.YJDXMC == "日常管理") {
            this.getBasePicList(); //获取基准照列表
        }

        // $(document).ready(function() {
        //     let tmp = document.getElementsByClassName("el-textarea__inner");
        //     if (tmp && tmp.length > 0 && tmp[0].className) {
        //         tmp.forEach((item) => {
        //             item.className = "el-textarea__inner scrollbar";
        //         });
        //     }
        // });
    },
    methods: {
        ...mapActions([
            "GetYjInfo", // 获取预警信息
            "GetYjgzpzData", // 获取预警跟踪配置数据
            "GetYjgzjlData", // 获取预警跟踪记录数据
            "GetUserList",
            "SaveYjgzpzData", // 保存预警跟踪配置数据
            "GetYjxxYjgzTask", // 下发预警配置任务
            "SaveYjczjgData",
            "GetRcxcycjlPicture", //选择基准照列表
            "SaveYjxxZdjy",
        ]),
        // 表头背景色
        getTitleBg(item) {
            let color = "";
            if (item.ID === this.ztNum) {
                color = "#89c997";
            } else if (
                (this.yjztNum === 0 || this.yjztNum === 2) &&
                item.ID > this.ztNum
            ) {
                color = "#lightgray";
            } else if (item.ID === this.currentStep) {
                color = "rgba(137, 201, 151,0.5)";
            } else {
                color = "rgba(0,0,0,0)";
            }
            return color;
        },
        // 表头 鼠标形状
        getMouse(item) {
            let mouse = "";
            if (item.ID === this.currentStep) {
                mouse = "auto";
            } else if (
                (this.yjztNum === 0 || this.yjztNum === 2) &&
                item.ID > this.ztNum
            ) {
                mouse = "not-allowed";
            } else {
                mouse = "pointer";
            }
            return mouse;
        },
        // 获取预警信息 或许最新状态 ztNum
        async getYjInfo() {
            let result = await this.GetYjInfo({
                yjsjId: this.id, // 预警数据ID
            });
            if (result && result.length > 0) {
                this.yjInfo = result[0];
                if (
                    this.yjInfo.PIC &&
                    this.yjInfo.PIC.length > 0 &&
                    this.yjInfo.PIC[0].CCLJ
                ) {
                    this.yjxxImgUrl = this.yjInfo.PIC[0].CCLJ;
                }
                this.changeYjczZt(this.yjInfo.YJZT, this.yjInfo.ZT);
            }
        },
        changeYjczZt(yjzt, zt) {
            // zt 就是处置步骤到哪一步了
            this.yjztNum = Number(yjzt); //预警状态
            this.ztNum = Number(zt);
            // console.log(
            //     "%c 🍮 zt 就是处置步骤到哪一步了 ",
            //     "font-size:20px;background-color: #42b983;color:#fff;",
            //     this.ztNum
            // );
            this.currentStep = this.ztNum;
        },

        // 预警跟踪 ------------------------------------------------------------------------
        // 获取人员列表
        async getUserList() {
            this.gzfzrList = [];
            let res = await this.GetUserList({
                keywords: "",
                depID: "",
                roleid: "",
                pageindex: 1,
                pagesize: 99999,
            });
            if (
                res &&
                res.result &&
                res.result.Data &&
                res.result.Data.length > 0
            ) {
                this.gzfzrList = res.result.Data;
            }
        },
        // 获取预警跟踪配置数据
        async getYjgzpzData() {
            let result = await this.GetYjgzpzData({
                yjsjId: this.id, // 预警数据ID
            });
            if (result.length) {
                this.yjgzpzData = result[0];
                if (this.yjgzpzData.FZRID) {
                    this.$refs.SnTreeCjr.setLabelName(this.yjgzpzData.FZRID);
                }
            }
        },
        // 确认下发任务
        yjgzSendTask() {
            if (!this.yjgzpzData.FZRID) {
                this.common.showMsg("选择跟踪负责人", "warning");
                return;
            }
            this.$refs.formYjgz.validate((valid) => {
                if (valid) {
                    this.$confirm("此操作将下发任务, 是否继续?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.yjgzSendTaskAct();
                        })
                        .catch(() => {
                            this.common.showmsg("已取消下发任务", "info");
                        });
                }
            });
        },
        //预警跟踪 下发任务
        async yjgzSendTaskAct() {
            let FZRMC = this.common.aryListFindOne(
                this.gzfzrList,
                "ID",
                this.yjgzpzData.FZRID
            ).REALNAME;
            // 保存预警跟踪配置数据
            let jsonStr = {
                ID: this.yjgzpzData.ID,
                FZRID: this.yjgzpzData.FZRID,
                FZRMC: FZRMC,
                KSSJ: this.yjgzpzData.KSSJ,
                LX: 2, //预警处置1 预警跟踪2
                YJSJID: this.id,
                ZQ: this.yjgzpzData.ZQ,
                ZQDW: this.yjgzpzData.ZQDW,
                // 年0月1周2天3时4分5秒6
                ZQDWENUM: this.yjgzpzData.ZQDW == "周" ? 2 : 3,
                YDDSYT:
                    this.gridItem.YJDXMC == "日常管理"
                        ? this.yjgzpzData.YDDSYT
                        : this.yjxxImgUrl, //图片的相对路径
            };
            let res = await this.SaveYjgzpzData({
                jsonStr: JSON.stringify(jsonStr),
            });

            if (res.ISSUCCESS) {
                let yjgzpzid = res.RESULTDESCRIPTION;
                // 下发预警配置任务
                let res2 = await this.GetYjxxYjgzTask({
                    yjgzpzid: yjgzpzid,
                });
                if (res2.ISSUCCESS) {
                    this.common.showMsg("保存并下发任务成功", "success");
                    this.getYjgzpzData();
                } else {
                    this.common.showMsg("下发任务失败", "error");
                }
            } else {
                this.common.showMsg("保存失败", "error");
            }
        },
        // 获取预警跟踪记录数据
        async getYjgzjlData() {
            let result = await this.GetYjgzjlData({
                yjsjId: this.id, // 预警事件ID
            });
            if (result && result.length > 0) {
                //  "SFJC": 0,//是否解除，1解除，0为解除
                result.map((item) => {
                    item.time = item.CJSJ.split(" ")[0];
                    item.SFJCMC = item.SFJC === 1 ? "已解除" : "未解除";
                    item.color = item.SFJC === 1 ? "#89c997" : "#dd4c38";
                });
                this.yjgzList = result;
                this.changeTime(this.yjgzList[0], 0);
            } else {
                this.yjgzList = [];
            }
        },
        // 只有当日常管理的时候 获取基准照列表
        async getBasePicList() {
            let result = await this.GetRcxcycjlPicture({
                sjid: this.gridItem.CJSJID ? this.gridItem.CJSJID : "", // 预警事件ID
            });
            if (result.ISSUCCESS) {
                this.basePicList = result.RESULTVALUE;
            }
        },
        getBasePicItem(item) {
            if (this.yjgzpzData.SFYRW !== 1) {
                this.yjgzpzData.YDDSYT = item.CCLJEX;
            }
        },
        // 点击预警跟踪时间轴
        changeTime(item, index) {
            this.yjgzDetail = item;
            this.timeActiveNum = index;
        },
        // 预警处置 -----------------------------------------------------------------------
        handleYjczStepClick(index) {
            if (index === this.currentStep) {
                return;
            }
            if (
                (this.yjztNum === 0 || this.yjztNum === 2) &&
                index > this.ztNum
            ) {
                return;
            }
            this.currentStep = index;
        },
        // 改变顶部状态
        async changeTopState(yjztNum, zt) {
            let res2 = await this.SaveYjczjgData({
                yjsjId: this.id,
                sfjcyj: yjztNum, //预警状态 0未处置 1处置中 2无效预警 3已解除
                sffsdx: 0, //是否发送短信，1是，0否
                zt: zt, //状态；1预警核实  2预警分发  3结果反馈  4处置跟踪 4处置工作完成
            });
            if (res2.ISSUCCESS) {
                this.common.showMsg("保存并提交成功", "success");
                this.getYjInfo();
            } else {
                this.common.showMsg("保存并提交失败", "error");
            }
        },
        // 指导建议 提交
        async zdjySubmit() {
            let res = await this.SaveYjxxZdjy({
                id: this.gridItem.ID,
                jy: this.gridItem.JY,
            });
            if (res.ISSUCCESS) {
                this.common.showMsg("提交成功", "success");
            } else {
                this.common.showMsg("提交失败", "error");
            }
        },
        getCjrItem(id) {
            this.yjgzpzData.FZRID = id;
        },
    },
};
</script>
<style lang="scss" scoped>
.yzjyPart {
    position: absolute;
    left: 41%;
    top: 61%;
    background: rgba(137, 201, 151, 0.2);
    z-index: 10;
    width: 300px;
    .titlePart {
        padding: 16px 20px;
        margin-bottom: 10px;
        .sideBar {
            width: 1px;
            height: 14px;
            background: rgba(63, 94, 70, 1);
        }
        .title {
            color: #3f5e46;
            margin-left: 10px;
        }
    }
    .inputPart {
        padding: 0 20px;
    }
    .btn {
        width: 100%;
        height: 40px;
        background: rgba(137, 201, 151, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        margin-top: 10px;
    }
}
.dataCtnForm {
    width: 100%;
    height: 100%;
    .tablePart {
        display: flex;
        .tableLeft {
            width: 630px;
            .yjInfoPart {
                height: 300px;
                .yjxxCtn {
                    display: flex;
                    height: calc(100% - 45px);
                    padding: 15px;
                    .img {
                        width: 245px;
                        height: 100%;
                    }
                    .yjxxDesc {
                        width: calc(100% - 245px);
                        padding-left: 20px;
                        .yjxxInfoItem {
                            height: 38px;
                            line-height: 38px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            .label {
                                color: #a6a6a6;
                                width: 80px;
                                text-align: right;
                            }
                            .yjdj {
                                padding: 0 4px;
                                border: 1px solid #fff;
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }
            .left-bottom {
                height: calc(100% - 300px);
                padding: 20px 0 0 0;
                .rwxf-form {
                    margin-top: 20px;
                }
                .yjgzCtn {
                    display: flex;
                    height: 380px;
                    .yjgzLeft {
                        position: relative;
                        width: 110px;
                        .timeList {
                            width: 95px;
                            height: 100%;
                            overflow-y: auto;
                            .timeItem {
                                line-height: 30px;
                                margin: 30px 0 60px;
                                text-align: center;
                                cursor: pointer;
                                &:last-child {
                                    margin-bottom: 30px;
                                }
                            }
                            .timeItemAvtive {
                                background: #89c997;
                                color: #3d453f;
                            }
                            .timeItemDefault {
                                background: #b9dabf;
                                color: #6d7570;
                            }
                        }
                        .axis-line {
                            position: absolute;
                            top: 0;
                            right: 15px;
                            bottom: 0;
                            width: 2px;
                            background: #89c997;
                        }
                    }
                    .yjgz-right {
                        width: calc(100% - 110px);
                        position: relative;
                        .yjgz-img {
                            height: calc(100% - 80px);
                            margin-bottom: 15px;
                        }
                        .yjgz-info {
                            height: 30px;
                            line-height: 30px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .sfjc-border {
                            position: absolute;
                            right: 0;
                            bottom: 20px;
                            width: 110px;
                            height: 40px;
                            line-height: 39px;
                            text-align: center;
                            border: 1px solid rgba(0, 0, 0, 0);
                            letter-spacing: 4px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        .tableRight {
            width: calc(100% - 630px);
            padding: 0 0 0 40px;
            .yjcz-steps {
                display: flex;
                margin: 40px 0;
                justify-content: space-between;
                .steps-label {
                    min-width: 90px;
                    height: 40px;
                    line-height: 36px;
                    padding: 0 10px;
                    border: 2px solid #89c897;
                    border-radius: 4px;
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    cursor: pointer;
                    &:last-child {
                        min-width: 120px;
                    }
                }
                .steps-line {
                    width: 65px;
                    height: 2px;
                    margin: 19px 0;
                    background: #bfbfbf;
                    margin-left: 50px;
                }
            }
        }
        .title {
            height: 45px;
            line-height: 45px;
            padding: 0 20px;
            border-bottom: 1px solid #cfcfcf;
            font-weight: bold;
        }
    }
}
</style>
