<template>
    <div class="yjczItemPart">
        <el-form
            ref="form"
            :model="yjhsFormData"
            label-width="90px"
            size="mini"
            label-position="left"
            class="yjhs-form"
        >
            <el-form-item label="受理为">
                <el-radio-group v-model="yjhsFormData.SFYXYJ">
                    <el-radio
                        :disabled="yjztNum !== 0"
                        v-for="(item, index) in sllxList"
                        :key="index"
                        :label="item.ID"
                        >{{ item.LABEL }}</el-radio
                    >
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if="yjhsFormData.SFYXYJ === 1" label="预警等级">
                <el-select
                    v-model="yjhsFormData.HSDJ"
                    placeholder="请选择预警等级"
                    style="width: 100%"
                    :disabled="yjztNum !== 0"
                >
                    <el-option
                        v-for="(item, index) in yjdjList"
                        :key="index + item.CODE"
                        :label="item.NAME"
                        :value="item.CODE"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="yjhsFormData.SFYXYJ === 1" label="类型">
                <el-select
                    v-model="yjhsFormData.HSYJLX"
                    placeholder="请选择类型"
                    style="width: 100%"
                    :disabled="yjztNum !== 0"
                >
                    <el-option
                        v-for="(item, index) in yjhslxList"
                        :key="index + item.YJDXBM"
                        :label="item.YJDXMC"
                        :value="item.ID"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="受理说明">
                <el-input
                    :disabled="yjztNum !== 0"
                    v-model="yjhsFormData.SLSM"
                    placeholder="请填写受理说明"
                    type="textarea"
                    :rows="6"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <div class="flex-end">
                    <el-button
                        v-show="yjztNum === 0"
                        type="primary"
                        @click="saveYjhsData()"
                        >保存并提交</el-button
                    >
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        yjztNum: {
            type: Number,
            default: -1,
        },
        ztNum: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            id: this.$route.query.id,
            gridItem: JSON.parse(this.$route.query.data),
            sllxList: [
                {
                    ID: 1,
                    LABEL: "警报",
                },
                {
                    ID: 0,
                    LABEL: "误报",
                },
            ],
            yjhsFormData: {
                SFYXYJ: 1, //受理为
                HSDJ: "", //预警等级
                HSYJLX: "", //类型
                SLSM: "", //受理说明
            },
            yjdjList: [], // 预警等级列表
            yjhslxList: [], // 预警核实-类型列表
        };
    },
    watch: {},
    mounted() {
        this.getYjdjList(); //预警等级列表
        this.getYjxList(); //预警类型列表
        this.getYjhsData(); //预警核实数据
    },
    methods: {
        ...mapActions([
            "GetEnumIdList", // 获取预警等级的枚举
            "GetYjxList", // 获取预警核实-类型数据
            "GetYjhsData", // 获取预警核实数据
            "SaveYjhsData", // 保存预警核实数据
            "SendMessageForYjjsr", // 短信发送
        ]),
        // 获取预警等级列表
        async getYjdjList() {
            this.yjdjList = [];
            let result = await this.GetEnumIdList({
                enumId: "212cb2aa-748a-11ea-bd8d-000c2977b7fd",
            });
            if (result.length) {
                this.yjdjList = result;
            }
        },
        // 获取预警核实-类型列表
        async getYjxList() {
            this.yjhslxList = [];
            let result = await this.GetYjxList();
            if (result.length) {
                this.yjhslxList = result;
            }
        },
        // 获取预警核实数据
        async getYjhsData() {
            let result = await this.GetYjhsData({
                yjsjId: this.id,
            });
            if (result.length) {
                let obj = result[0];
                this.yjhsFormData.SFYXYJ = obj.SFYXYJ; //受理为
                this.yjhsFormData.HSDJ = obj.HSDJ; //预警等级
                this.yjhsFormData.HSYJLX = obj.HSYJLX; //类型
                this.yjhsFormData.SLSM = obj.SLSM; //受理说明
            }
        },
        // 保存预警核实数据
        async saveYjhsData() {
            let jsonStr = {
                ID: this.id,
                SFYXYJ: this.yjhsFormData.SFYXYJ, //是否有效预警：0：无效 1：有效',
                HSR: localStorage.userId, //核实人,
                HSDJ:
                    this.yjhsFormData.SFYXYJ == 1 ? this.yjhsFormData.HSDJ : "", //预警等级
                HSYJLX:
                    this.yjhsFormData.SFYXYJ == 1
                        ? this.yjhsFormData.HSYJLX
                        : "", //类型
                SLSM: this.yjhsFormData.SLSM,
                YJZT: this.yjhsFormData.SFYXYJ == 1 ? 1 : 2, //0未处置 1处置中 2无效预警 3已解除
            };
            // 保存预警核实数据
            let res = await this.SaveYjhsData({
                jsonStr: JSON.stringify(jsonStr),
            });

            if (res.ISSUCCESS) {
                if (this.yjhsFormData.SFYXYJ == 1) {
                    // 预警状态 步骤状态
                    this.$emit("changeTopState", 1, 1);
                } else {
                    // 误报
                    this.$emit("changeTopState", 2, 0);
                }
            } else {
                this.common.showMsg("保存并提交失败", "error");
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
