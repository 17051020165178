<template>
    <div class="yjczItemPart">
        <div
            v-for="(item, index) in czgzDataList"
            :key="index"
            class="czgzItem"
        >
            <div class="czgzHeader">
                <div class="fknr">{{ item.FKNR }}</div>
                <div class="flex-between">
                    <div
                        class="sfygz"
                        :style="{
                            color: czgzColors[Number(item.SFYGZSJ)],
                        }"
                    >
                        {{
                            item.SFYGZSJ && item.SFYGZSJ === "1"
                                ? "已跟踪"
                                : "未跟踪"
                        }}
                    </div>
                    <div>
                        <span>{{ item.FKR }}</span>
                        <span>{{ item.FKSJ }}</span>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    item.SFYGZSJ === '1' &&
                    item.CZGZJL &&
                    item.CZGZJL.length > 0
                "
                class="czgz-body"
            >
                <div class="czgz-text">
                    <div class="czgz-xcms">
                        {{ item.CZGZJL[0].XCMS }}
                    </div>
                    <div>
                        <span class="czgz-cjrmc">{{
                            item.CZGZJL[0].CJRMC
                        }}</span>
                        <span>{{ item.CZGZJL[0].CJSJ }}</span>
                    </div>
                </div>
                <div
                    v-if="item.CZGZJL[0].PIC && item.CZGZJL[0].PIC.length > 0"
                    class="czgz-img-border"
                >
                    <el-image
                        :src="item.CZGZJL[0].PIC[0].CCLJ"
                        fit="cover"
                        style="width: 100%; height: 100%"
                        class="pointer"
                        v-viewer
                    >
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData typeNum="1"></SnNoData>
                        </div>
                    </el-image>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        yjztNum: {
            type: Number,
            default: -1,
        },
        ztNum: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            id: "",
            gridItem: null,
            czgzDataList: [],
            czgzColors: ["#d9001b", "#70b603"],
        };
    },
    watch: {},
    mounted() {
        this.id = this.$route.query.id;
        this.gridItem = JSON.parse(this.$route.query.data);
        this.getCzgzjlData();
    },
    methods: {
        ...mapActions([
            "GetCzgzjlData", // 获取处置跟踪记录数据
        ]),
        // 获取处置跟踪列表
        async getCzgzjlData() {
            this.czgzDataList = [];
            let result = await this.GetCzgzjlData({
                yjsjId: this.id,
            });
            if (result && result.length > 0) {
                this.czgzDataList = result;
                //     if (this.yjztNum !== 3) {
                //         let res2 = await this.SaveYjczjgData({
                //             yjsjId: this.id,
                //             sfjcyj: "1",
                //             sffsdx: "0",
                //             zt: "4",
                //         });
                //         if (res2.ISSUCCESS) {
                //             if (this.ztNum > 0 && this.ztNum < 3) {
                //                 this.changeYjczZt("1", "3");
                //             }
                //         }
                //     }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.yjczItemPart {
    width: 100%;
    .czgzItem {
        width: 100%;
        min-height: 100px;
        .czgzHeader {
            border-bottom: 1px solid #7a7a7a;
            padding: 0 40px 2px 10px;
            .fknr {
                font-size: 16px;
            }
            .sfygz {
                font-size: 16px;
            }
        }
        .czgz-body {
            display: flex;
            justify-content: space-between;
            padding: 10px 80px 40px 20px;
            .czgz-text {
                width: calc(100% - 420px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .czgz-xcms {
                    height: 28px;
                    font-size: 15px;
                }
                .czgz-cjrmc {
                    margin-right: 20px;
                }
            }
            .czgz-img-border {
                width: 400px;
                height: 230px;
                .czgz-image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>
