<template>
    <div class="yjczItemPart">
        <div class="flex-between actionBtn">
            <div>预警上报{{ yjsbTableTotal }}条</div>
            <el-button
                v-show="yjztNum !== 3"
                type="primary"
                size="mini"
                @click="openSbjlList()"
                >新增</el-button
            >
        </div>
        <!-- 列表 -->
        <div class="yjsbTable">
            <el-table
                :data="yjsbTableData"
                style="width: 100%"
                max-height="250"
                size="small"
                class="yjsb-table"
                @row-click="getTableItem"
            >
                <el-table-column
                    prop="CZBM"
                    label="接收部门"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                    prop="CZR"
                    label="接收人"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                    prop="CZSJ"
                    label="上报时间"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <div>
                            {{
                                scope.row.CZSJ
                                    ? scope.row.CZSJ.substr(0, 10)
                                    : ""
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="CJRNAME"
                    label="上报人"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                    prop="SBFSNAME"
                    label="上报方式"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column label="附件">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            class="yjsb-file-btn"
                            @click.native.prevent="
                                getFile(scope.$index, scope.row)
                            "
                            >{{
                                scope.row.PIC.length > 0 ? "查看" : ""
                            }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 预警上报输入 -->
        <transition name="el-zoom-in-top">
            <div v-if="enterState">
                <div class="flex-between actionBtn">
                    <div>上报记录</div>
                    <div>
                        <el-button
                            v-show="
                                yjztNum !== 3 &&
                                    Number(yjsbFormData.SFYFK) !== 1
                            "
                            type="primary"
                            class="yjhs-savebtn"
                            size="mini"
                            @click="saveYjsbData()"
                            >保存</el-button
                        >
                        <el-button
                            type="primary"
                            class="yjhs-savebtn"
                            size="mini"
                            @click="closeSbjlList()"
                            >取消</el-button
                        >
                    </div>
                </div>

                <el-form
                    ref="yjsbForm"
                    :model="yjsbFormData"
                    :rules="rules"
                    label-width="90px"
                    size="mini"
                    label-position="left"
                    class="yjsb-form"
                >
                    <el-form-item label="接收部门" prop="CZBM">
                        <el-select
                            v-model="yjsbFormData.CZBM"
                            :disabled="yjztNum === 3"
                            filterable
                            allow-create
                            default-first-option
                            placeholder="请选择接收部门"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="(item, index) in yjsbJsbmList"
                                :key="index + 'jsbm' + item.ID"
                                :label="item.NAME"
                                :value="item.NAME"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="接收人" prop="CZR">
                        <el-select
                            v-model="yjsbFormData.CZR"
                            :disabled="yjztNum === 3"
                            filterable
                            allow-create
                            default-first-option
                            placeholder="请选择接收人"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="(item, index) in gzfzrList"
                                :key="index + 'jsr' + item.ID"
                                :label="item.REALNAME"
                                :value="item.REALNAME"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="事件描述">
                        <el-input
                            v-model="yjsbFormData.CZJGMS"
                            :disabled="yjztNum === 3"
                            placeholder="请填写事件描述"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="上报时间" prop="CZSJ">
                        <el-date-picker
                            v-model="yjsbFormData.CZSJ"
                            :disabled="yjztNum === 3"
                            type="date"
                            placeholder="请选择上报时间"
                            style="width: 100%"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="上报方式" prop="SBFS">
                        <el-select
                            v-model="yjsbFormData.SBFS"
                            :disabled="yjztNum === 3"
                            filterable
                            placeholder="请选择上报方式"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="(item, index) in yjsbSbfsList"
                                :key="index + 'sbfs' + item.CODE"
                                :label="item.NAME"
                                :value="item.CODE"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="附件">
                        <el-upload
                            v-if="
                                yjztNum !== 3 &&
                                    Number(yjsbFormData.SFYFK) !== 1
                            "
                            :action="`${uploadURL}UpLoad/FileSave?LJ=YJSB`"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            :on-success="handleSuccess"
                            :multiple="false"
                            :limit="1"
                            :class="{
                                disableShow: fileList.length === 1,
                            }"
                        >
                            <el-button size="small" type="primary"
                                >点击上传</el-button
                            >
                            <div
                                slot="tip"
                                class="el-upload__tip"
                                v-if="!fileList.length"
                            >
                                上传说明：只能上传1个附件！
                            </div>
                        </el-upload>
                    </el-form-item>
                </el-form>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        yjztNum: {
            type: Number,
            default: -1,
        },
        ztNum: {
            type: Number,
            default: -1,
        },
        // 人员列表
        gzfzrList: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            id: "",
            gridItem: null,
            yjsbTableData: [], //表单数据
            yjsbTableTotal: 0, //数据总条数
            yjsbJsbmList: [], //接收部门列表
            yjsbSbfsList: [], //上报方式列表
            yjsbFormData: {
                ID: "",
                YJSJID: this.id,
                CZBM: "", //接收部门
                CZR: "", //接收人
                CZJGMS: "", //事件描述
                CZSJ: "", //上报时间
                SBFS: "", //上报方式
                PIC: [],
            },
            rules: {
                CZBM: [
                    {
                        required: true,
                        message: "请选择接收部门",
                        trigger: "blur",
                    },
                ],
                CZR: [
                    {
                        required: true,
                        message: "请选择接收人",
                        trigger: "blur",
                    },
                ],
                CZSJ: [
                    {
                        required: true,
                        message: "请选择上报时间",
                        trigger: "blur",
                    },
                ],
            },
            fileList: [],
            uploadURL: window.REQUEST_URL,
            fileIdsDelYjsb: [], //附件id 删除的
            enterState: false, //显隐 上报列表
        };
    },
    watch: {},
    mounted() {
        this.id = this.$route.query.id;
        this.gridItem = JSON.parse(this.$route.query.data);
        this.getYjsbJsbmList(); //接收部门列表
        this.getYjsbSbfsList(); //上报方式列表
        this.getYjsbList(); //预警上报列表
    },
    methods: {
        ...mapActions([
            "GetEnumIdList",
            "GetYjsbList", // 获取预警上报列表
            "DeleteFiles", // 删除附件
            "SaveYjsbData", // 保存预警上报数据
            "getDepNameListOrig", // 获取接收部门列表
        ]),
        // 获取预警上报列表
        async getYjsbList() {
            this.yjsbTableData = [];
            this.yjsbTableTotal = 0;
            let result = await this.GetYjsbList({
                yjsjId: this.id,
            });
            if (result) {
                if (result.DATA && result.DATA.length > 0) {
                    this.yjsbTableData = result.DATA;
                }
                if (result.TOTAL) {
                    this.yjsbTableTotal = result.TOTAL;
                }
            }
        },
        // 表格查看附件
        getFile(index, row) {
            if (row.PIC.length) {
                window.open(row.PIC[0].CCLJ);
            }
        },
        // 点击预警上报表格的某一行
        getTableItem(row, column, event) {
            this.enterState = true;
            this.fileList = [];
            let obj = JSON.parse(JSON.stringify(row));
            this.yjsbFormData.ID = obj.ID;
            this.yjsbFormData.CZBM = obj.CZBM; //接收部门
            this.yjsbFormData.CZR = obj.CZR; //接收人
            this.yjsbFormData.CZJGMS = obj.CZJGMS; //事件描述
            this.yjsbFormData.CZSJ = obj.CZSJ.split(" ")[0]; //上报时间
            this.yjsbFormData.SBFS = obj.SBFS; //上报方式
            this.yjsbFormData.PIC = obj.PIC;

            if (obj.PIC.length) {
                this.fileList.push({
                    name: obj.PIC[0].WDMC,
                    url: obj.PIC[0].CCLJ,
                });
            }
        },
        // 初始化预警上报表单数据
        initYjsbFormData() {
            this.yjsbFormData = {
                YJSJID: this.id,
                CZBM: "",
                CZR: "",
                CZJGMS: "",
                CZSJ: this.common.getTimeYmd(),
                SBFS: "",
                PIC: [],
            };
            this.fileList = [];
            this.fileIdsDelYjsb = [];
        },
        // 打开上报记录
        openSbjlList() {
            this.enterState = true;
            this.initYjsbFormData();
        },
        // 关闭上报记录
        closeSbjlList() {
            this.enterState = false;
            this.initYjsbFormData();
        },
        // 获取接收部门列表
        async getYjsbJsbmList() {
            this.yjsbJsbmList = [];
            let result = await this.getDepNameListOrig();
            if (result && result.length) {
                this.yjsbJsbmList = result;
            }
        },
        // 获取上报方式列表
        async getYjsbSbfsList() {
            this.yjsbSbfsList = [];
            let result = await this.GetEnumIdList({
                enumId: "ed6fc647-c48c-4dc2-82af-8a4c789a370a",
            });
            if (result && result.length > 0) {
                this.yjsbSbfsList = result;
            }
        },
        handlePreview(file) {
            if (!file) return;
            if (file.url) {
                window.open(file.url);
            } else if (file.CCLJ) {
                window.open(file.CCLJ);
            } else if (
                file.response &&
                file.response.ResultValue &&
                file.response.ResultValue.length > 0 &&
                file.response.ResultValue[0].FilePath
            ) {
                window.open(file.response.ResultValue[0].FilePath);
            }
        },
        handleRemove(file, fileList) {
            this.fileIdsDelYjsb.push(this.yjsbFormData.PIC[0].FJID);
            this.fileList = [];
            this.yjsbFormData.PIC = [];
        },
        handleSuccess(res, file) {
            this.yjsbFormData.PIC = [];
            this.fileList = [];
            let obj = {
                FJID: res.ResultValue[0].Guid,
                WDMC: res.ResultValue[0].FileName,
                CCLJ: res.ResultValue[0].FilePath_ex,
            };
            this.yjsbFormData.PIC.push(obj);
            this.fileList.push(file);
        },

        // 保存预警上报数据 条件监测
        saveYjsbData() {
            this.$refs.yjsbForm.validate((valid) => {
                if (valid) {
                    this.deleteFiles();
                }
            });
        },
        // 删除上传没用的文件
        async deleteFiles() {
            if (this.fileIdsDelYjsb.length > 0) {
                let fileIds = this.fileIdsDelYjsb.toString();
                let fileRes = await this.DeleteFiles({
                    fileIds: fileIds,
                });
                if (fileRes.ISSUCCESS) {
                    this.fileIdsDelYjsb = [];
                    this.saveYjsbDataAct();
                } else {
                    this.common.showMsg("保存失败", "error");
                }
            } else {
                this.saveYjsbDataAct();
            }
        },
        // 保存预警上报数据
        async saveYjsbDataAct() {
            let fileids = "";
            if (this.yjsbFormData.PIC.length > 0) {
                fileids = this.yjsbFormData.PIC[0].FJID;
            }

            let jsonStr = {
                ID: this.yjsbFormData.ID ? this.yjsbFormData.ID : "",
                YJSJID: this.id,
                CZBM: this.yjsbFormData.CZBM,
                CZR: this.yjsbFormData.CZR,
                CZJGMS: this.yjsbFormData.CZJGMS,
                CZSJ: this.yjsbFormData.CZSJ,
                SBFS: this.yjsbFormData.SBFS ? this.yjsbFormData.SBFS : "",
                CJRID: localStorage.userId,
            };

            let res = await this.SaveYjsbData({
                jsonStr: JSON.stringify(jsonStr),
                fileids: fileids,
            });
            if (res.ISSUCCESS) {
                // 预警状态 0未处置 1处置中 2无效预警 3已解除 步骤状态
                this.$emit("changeTopState", 1, 2);
            } else {
                this.common.showMsg("保存失败", "error");
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.yjczItemPart {
    .actionBtn {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid #797979;
        padding: 2px 20px 10px 20px;
    }
}
</style>
