<template>
    <div class="yjczItemPart">
        <el-form
            ref="form"
            :model="formData"
            label-width="300px"
            size="mini"
            label-position="left"
            class="yjhs-form"
        >
            <el-form-item label="是否解除预警">
                <el-radio-group
                    v-model="formData.warnState"
                    style="width: 100%"
                    @change="changeWarnState()"
                    :disabled="yjztNum === 3 || yjInfo.YJDXMC == '自然环境'"
                >
                    <el-radio
                        v-for="(item, index) in yesNoList"
                        :key="index"
                        :label="item.ID"
                        >{{ item.LABEL }}</el-radio
                    >
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否给预警信息接收人发送预警解除短信提示">
                <el-radio-group
                    v-model="formData.messageState"
                    style="width: 100%"
                    :disabled="yjztNum === 3"
                >
                    <el-radio
                        v-for="(item, index) in yesNoList"
                        :key="index"
                        :label="item.ID"
                        >{{ item.LABEL }}</el-radio
                    >
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <el-button
                    v-show="yjztNum !== 3"
                    type="primary"
                    @click="saveYjczjgData()"
                    >处置工作完成</el-button
                >
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        yjztNum: {
            type: Number,
            default: -1,
        },
        ztNum: {
            type: Number,
            default: -1,
        },
        yjInfo: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            id: "",
            gridItem: null,
            // 预警处置-4.处置工作完成 ----------------
            formData: {
                warnState: "0",
                messageState: "0",
            },
            yesNoList: [
                {
                    ID: "1",
                    LABEL: "是",
                },
                {
                    ID: "0",
                    LABEL: "否",
                },
            ],
        };
    },
    watch: {},
    mounted() {
        this.id = this.$route.query.id;
        this.gridItem = JSON.parse(this.$route.query.data);
        this.getYjczjgValue();
    },
    methods: {
        ...mapActions([
            "GetYjczjgValue", // 获取预警处置结果
            "SaveYjczjgData", // 保存预警处置结果
        ]),
        // 获取处置工作完成情况
        async getYjczjgValue() {
            let result = await this.GetYjczjgValue({
                yjsjId: this.id,
            });
            if (result) {
                // 是否解除预警
                this.formData.warnState = Number(result.YJZT) === 3 ? "1" : "0";
                // 是否给预警信息接收人发送预警解除短信提示
                this.formData.messageState = result.SFFSDX.toString();
            }
        },
        // 保存处置工作完成情况
        async saveYjczjgData() {
            let res = await this.SaveYjczjgData({
                yjsjId: this.id,
                sfjcyj:
                    Number(this.formData.warnState) === 1 ? "3" : this.yjztNum,
                sffsdx: this.formData.messageState,
                zt: "4",
            });
            if (res.ISSUCCESS) {
                this.$emit("changeTopLast");
                this.common.showMsg("保存成功", "success");
            } else {
                this.common.showMsg("保存失败", "success");
            }
        },
        // 预警状态改变
        changeWarnState() {
            if (this.formData.warnState == "0") {
                this.formData.messageState = "0";
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
