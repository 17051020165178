<template>
    <div class="yjczItemPart">
        <div>处置结果{{ totalNum }}条</div>
        <!-- 表单 -->
        <div class="yjsbTable">
            <el-table
                :data="tableList"
                style="width: 100%"
                max-height="250"
                size="small"
                @row-click="getTableItem"
            >
                <el-table-column
                    prop="FKBM"
                    label="反馈部门"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                    prop="FKR"
                    label="反馈人"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                    prop="FKSJ"
                    label="反馈时间"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    prop="FKNR"
                    label="反馈内容"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column label="附件">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            class="yjsb-file-btn"
                            @click.native.prevent="
                                handleTableFileClick(scope.$index, scope.row)
                            "
                            >{{
                                scope.row.PIC && scope.row.PIC.length > 0
                                    ? "查看"
                                    : ""
                            }}</el-button
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="SFYRW" label="是否已下发跟踪任务">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.SFYRW === "1" ? "是" : "否" }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80">
                    <template slot-scope="scope">
                        <i
                            class="el-icon-edit"
                            style="cursor: pointer"
                            title="反馈"
                            @click="getTableItem(scope.row)"
                        ></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 修改表单某一项 -->
        <transition name="el-zoom-in-top">
            <div v-if="enterState" class="configPart">
                <div class="flex-between actionBtn">
                    <div class="">处置结果记录</div>
                    <div>
                        <el-button
                            v-show="yjztNum !== 3"
                            type="primary"
                            class="yjhs-savebtn"
                            size="mini"
                            @click="saveJgfkData()"
                            >保存</el-button
                        >
                        <el-button
                            type="primary"
                            class="yjhs-savebtn"
                            size="mini"
                            @click="closeConfigList()"
                            >取消</el-button
                        >
                    </div>
                </div>

                <el-form
                    ref="czjgjlForm"
                    :rules="czjgjlRules"
                    :model="czjgfkData"
                    label-width="90px"
                    size="mini"
                    label-position="left"
                    :inline="true"
                    class="enterForm"
                >
                    <el-form-item
                        label="反馈部门"
                        class="czjgjlItem"
                        prop="FKBM"
                    >
                        <el-select
                            v-model="czjgfkData.FKBM"
                            filterable
                            allow-create
                            default-first-option
                            placeholder="请选择反馈部门"
                            style="width: 100%"
                            :disabled="true"
                        >
                            <el-option
                                v-for="(item, index) in yjsbJsbmList"
                                :key="index + 'fkbm' + item.ID"
                                :label="item.NAME"
                                :value="item.NAME"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="反馈人" class="czjgjlItem" prop="FKR">
                        <el-select
                            v-model="czjgfkData.FKR"
                            :disabled="yjztNum === 3"
                            filterable
                            allow-create
                            default-first-option
                            placeholder="请选择反馈人"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="(item, index) in gzfzrList"
                                :key="index + 'fkr' + item.ID"
                                :label="item.REALNAME"
                                :value="item.REALNAME"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item
                        label="反馈内容"
                        class="czjgjlItem"
                        prop="FKNR"
                    >
                        <el-input
                            v-model="czjgfkData.FKNR"
                            :disabled="yjztNum === 3"
                            placeholder="请填写反馈内容"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="时间" class="czjgjlItem" prop="FKSJ">
                        <el-date-picker
                            v-model="czjgfkData.FKSJ"
                            :disabled="yjztNum === 3"
                            type="date"
                            placeholder="请选择时间"
                            style="width: 100%"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="附件" style="width: 100%">
                        <el-upload
                            :disabled="yjztNum === 3"
                            :action="`${uploadURL}UpLoad/FileSave?LJ=CZJGFK`"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            :on-success="handleSuccess"
                            :multiple="false"
                            :limit="1"
                            :class="{
                                disableShow: fileList.length === 1,
                            }"
                        >
                            <el-button size="small" type="primary"
                                >点击上传</el-button
                            >
                            <div
                                slot="tip"
                                class="el-upload__tip"
                                v-if="!fileList.length"
                            >
                                上传说明：只能上传1个附件！
                            </div>
                        </el-upload>
                    </el-form-item>
                    <!-- 是否对处置结果进行跟踪 -->
                    <div>
                        <span style="margin-right: 20px"
                            >是否对处置结果进行跟踪</span
                        >
                        <el-radio-group
                            v-model="czjgfkData.SFYCZJGGZ"
                            :disabled="yjztNum === 3"
                        >
                            <el-radio
                                v-for="(item, index) in yesNoList"
                                :key="index"
                                :label="item.ID"
                                >{{ item.LABEL }}</el-radio
                            >
                        </el-radio-group>
                    </div>
                </el-form>
                <!-- 处置结果跟踪 -->
                <div v-if="czjgfkData.SFYCZJGGZ === '1'">
                    <div class="title">处置结果跟踪</div>
                    <div class="czjggzPart">
                        <div>
                            <label class="label">是否已有跟踪数据</label>
                            <el-radio-group
                                v-model="czjgfkData.SFYGZSJ"
                                :disabled="yjztNum === 3"
                            >
                                <el-radio
                                    v-for="(item, index) in yesNoList"
                                    :key="index"
                                    :label="item.ID"
                                    >{{ item.LABEL }}</el-radio
                                >
                            </el-radio-group>
                        </div>
                        <!-- 选择预警跟踪 -->
                        <div
                            v-if="czjgfkData.SFYGZSJ === '1'"
                            class="yygzsj-border"
                        >
                            <div class="label">请选择已有跟踪数据</div>
                            <div>
                                <label class="label">起止时间</label>
                                <el-date-picker
                                    v-model="gzTime"
                                    :disabled="yjztNum === 3"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    size="mini"
                                    @change="getGzList"
                                >
                                </el-date-picker>
                            </div>
                            <div class="xzyygzsj-border">
                                <div
                                    v-for="(item1, index1) in gzList"
                                    :key="index1"
                                    class="yygzsj-item"
                                >
                                    <div class="gzsj-header flex-between">
                                        <div class="gzsj-head-date">
                                            {{ item1.CJSJ }}
                                        </div>
                                        <el-checkbox
                                            v-model="item1.CHECKED"
                                            :disabled="yjztNum === 3"
                                            @change="
                                                changeGzjlItem(
                                                    item1.CHECKED,
                                                    index1
                                                )
                                            "
                                        ></el-checkbox>
                                    </div>
                                    <div class="gzsj-body flex-between">
                                        <div class="gzsj-text">
                                            <div class="gzsj-label">
                                                <span>情况描述：</span>
                                                <span>{{ item1.XCMS }}</span>
                                            </div>
                                            <div>
                                                <span>巡查员：</span>
                                                <span>{{ item1.CJRMC }}</span>
                                            </div>
                                        </div>
                                        <div class="gzsj-img-border">
                                            <el-image
                                                :src="item1.XCZP"
                                                fit="cover"
                                                style="
                                                    width: 100%;
                                                    height: 100%;
                                                "
                                                class="pointer"
                                                v-viewer
                                            >
                                                <div
                                                    slot="error"
                                                    style="
                                                        width: 100%;
                                                        height: 100%;
                                                    "
                                                >
                                                    <SnNoData
                                                        typeNum="1"
                                                        width="50px"
                                                    ></SnNoData>
                                                </div>
                                            </el-image>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 请下发跟踪任务 -->
                        <div v-else>
                            <div class="label">请下发跟踪任务</div>
                            <el-form
                                ref="form"
                                :model="taskData"
                                label-width="90px"
                                size="mini"
                                label-position="left"
                                class="enterForm"
                            >
                                <el-form-item label="负责人" class="czjgjlItem">
                                    <el-select
                                        v-model="taskData.FZRID"
                                        :disabled="yjztNum === 3"
                                        filterable
                                        placeholder="请选择接收人"
                                        style="width: 100%"
                                    >
                                        <el-option
                                            v-for="(item, index) in gzfzrList"
                                            :key="index + 'jsr' + item.ID"
                                            :label="item.REALNAME"
                                            :value="item.ID"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item
                                    label="起止时间"
                                    class="czjgjlItem"
                                >
                                    <el-date-picker
                                        v-model="taskTime"
                                        :disabled="yjztNum === 3"
                                        style="width: 100%"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        value-format="yyyy-MM-dd"
                                        format="yyyy-MM-dd"
                                        @change="jsrTimeChange()"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        yjztNum: {
            type: Number,
            default: -1,
        },
        ztNum: {
            type: Number,
            default: -1,
        },
        // 人员列表
        gzfzrList: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            id: "",
            gridItem: null,
            tableList: [],
            totalNum: 0,
            czjgfkData: {
                ID: "",
                FKBM: "", // 反馈部门
                FKR: "", // 反馈人
                FKNR: "", // 反馈内容
                FKSJ: "", // 反馈时间
                SFYGZSJ: "0", //是否已有跟踪数据
                SFYCZJGGZ: "0", //是否对处置结果进行跟踪
                PIC: [],
            },
            czjgjlRules: {
                FKR: [
                    {
                        required: true,
                        message: "请选择反馈人",
                        trigger: "blur",
                    },
                ],
                FKNR: [
                    {
                        required: true,
                        message: "请输入反馈内容",
                        trigger: "blur",
                    },
                ],
                FKSJ: [
                    {
                        required: true,
                        message: "请选择反馈时间",
                        trigger: "blur",
                    },
                ],
            },
            yesNoList: [
                {
                    ID: "1",
                    LABEL: "是",
                },
                {
                    ID: "0",
                    LABEL: "否",
                },
            ], // 是否已有跟踪数据列表
            gzTime: [], //已有跟踪数据 起止时间
            gzList: [], //跟踪列表
            taskTime: [], //任务 起止时间
            taskData: {
                YJSJID: this.id,
                KSSJ: "",
                JSSJ: "",
                LX: "1",
                FZRID: "",
                FZRMC: "",
            },
            fileList: [],
            gzjlItem: null, //某一条跟踪记录
            fileIdsDelCzjgfk: [],
            enterState: false, //显隐 上报列表
            yjsbJsbmList: [], //接收部门列表
            uploadURL: window.REQUEST_URL,
        };
    },
    watch: {},
    mounted() {
        this.id = this.$route.query.id;
        this.gridItem = JSON.parse(this.$route.query.data);
        this.closeConfigList();
        this.getCzjgfkList();
        this.getYjsbJsbmList();
    },
    methods: {
        ...mapActions([
            "GetCzjgList", // 获取处置结果列表
            "GetCzjggzData", // 获取处置结果跟踪数据
            "SaveCzjgData", // 保存处置结果数据
            "getDepNameListOrig",
            "GetYjxxYjgzTask", //下发任务
        ]),
        // 获取接收部门列表
        async getYjsbJsbmList() {
            this.yjsbJsbmList = [];
            let result = await this.getDepNameListOrig();
            if (result && result.length) {
                this.yjsbJsbmList = result;
            }
        },
        // 获取处置结果反馈列表
        async getCzjgfkList() {
            this.tableList = [];
            this.totalNum = 0;
            let yjsjId = this.id;
            let result = await this.GetCzjgList({
                yjsjId: yjsjId,
            });
            if (result && result.length > 0) {
                result.map((item) => {
                    item.FKSJ = item.FKSJ.split(" ")[0];
                });
                this.tableList = result;
                this.totalNum = result.length;
            }
        },
        // 点击处置结果反馈表格的某一行
        getTableItem(row, column, event) {
            this.fileList = [];
            let obj = JSON.parse(JSON.stringify(row));
            this.czjgfkData.ID = obj.ID;
            this.czjgfkData.FKBM = obj.FKBM;
            this.czjgfkData.FKR = obj.FKR;
            this.czjgfkData.FKNR = obj.FKNR;
            this.czjgfkData.FKSJ = obj.FKSJ.split(" ")[0];
            this.czjgfkData.SFYCZJGGZ =
                obj.SFYCZJGGZ == "" ? "0" : obj.SFYCZJGGZ; //是否对处置结果进行跟踪 0否 1 是
            this.czjgfkData.SFYGZSJ = obj.SFYGZSJ; //是否已有跟踪数据0否 1 是
            this.czjgfkData.PIC = obj.PIC;
            if (obj.PIC.length) {
                this.fileList.push({
                    name: this.czjgfkData.PIC[0].WDMC,
                    url: this.czjgfkData.PIC[0].CCLJ,
                });
            }
            this.enterState = true;
            this.getGzList();
        },
        // 取消保存处置结果反馈数据
        closeConfigList() {
            this.enterState = false;
            this.initYjjgfkFormData();
        },
        // 初始化处置结果反馈表单数据
        initYjjgfkFormData() {
            this.czjgfkData = {
                ID: "",
                FKBM: "", // 反馈部门
                FKR: "", // 反馈人
                FKNR: "", // 反馈内容
                FKSJ: "", // 反馈时间
                SFYGZSJ: "0", //是否已有跟踪数据
                SFYCZJGGZ: "0", //是否对处置结果进行跟踪
                PIC: [],
            };
            this.fileList = [];
            this.fileIdsDelCzjgfk = [];
        },
        handlePreview(file) {
            if (!file) return;
            if (file.url) {
                window.open(file.url);
            } else if (file.CCLJ) {
                window.open(file.CCLJ);
            } else if (
                file.response &&
                file.response.ResultValue &&
                file.response.ResultValue.length > 0 &&
                file.response.ResultValue[0].FilePath
            ) {
                window.open(file.response.ResultValue[0].FilePath);
            }
        },
        handleRemove(file, fileList) {
            this.fileIdsDelCzjgfk.push(this.czjgfkData.PIC[0].FJID);
            this.fileList = [];
            this.czjgfkData.PIC = [];
        },
        handleSuccess(res, file) {
            this.czjgfkData.PIC = [];
            this.fileList = [];

            let obj = {
                FJID: res.ResultValue[0].Guid,
                WDMC: res.ResultValue[0].FileName,
                CCLJ: res.ResultValue[0].FilePath_ex,
            };
            this.czjgfkData.PIC.push(obj);

            this.fileList.push(file);
        },
        // 获取 跟踪数据
        async getGzList() {
            this.gzList = [];
            this.taskTime = [];
            let startTime = "";
            let endTime = "";
            if (this.gzTime && this.gzTime.length) {
                startTime = this.gzTime[0];
                endTime = this.gzTime[1];
            }
            let result = await this.GetCzjggzData({
                yjsjId: this.id,
                czjlId: this.czjgfkData.ID, // 预警处置记录ID
                startTime: startTime,
                endTime: endTime,
            });

            //预警跟踪数据
            if (result.YJGZJLDT.length) {
                result.YJGZJLDT.forEach((item) => {
                    let obj = {
                        ...item,
                        CHECKED: false,
                    };
                    this.gzList.push(obj);
                });
            }
            // 预警跟踪配置
            if (result.YJGZPZDT.length) {
                result.YJGZPZDT[0].KSSJ = result.YJGZPZDT[0].KSSJ.split(" ")[0];
                result.YJGZPZDT[0].JSSJ = result.YJGZPZDT[0].JSSJ.split(" ")[0];
                this.taskData = result.YJGZPZDT[0];
                this.taskTime = [this.taskData.KSSJ, this.taskData.JSSJ];
            }
        },
        // 改变某一个跟踪记录
        changeGzjlItem(val, ind) {
            if (val) {
                this.gzList.forEach((item, index) => {
                    if (index === ind) {
                        this.gzjlItem = item;
                    } else {
                        item.CHECKED = false;
                    }
                });
            } else {
                this.gzjlItem = null;
            }
        },
        // 接收人时间改变
        jsrTimeChange() {
            if (this.taskTime.length) {
                this.taskData.KSSJ = this.taskTime[0];
                this.taskData.JSSJ = this.taskTime[1];
            } else {
                this.taskData.KSSJ = "";
                this.taskData.JSSJ = "";
            }
        },
        // 表格查看附件
        handleTableFileClick(index, row) {
            if (row && row.PIC && row.PIC.length > 0) {
                window.open(row.PIC[0].CCLJ);
            }
        },
        // 保存预警上报数据 条件监测
        saveJgfkData() {
            this.$refs.czjgjlForm.validate((valid) => {
                if (valid) {
                    this.deleteFiles();
                }
            });
        },
        //结果反馈 删除处置结果附件
        async deleteFiles() {
            if (this.fileIdsDelCzjgfk.length > 0) {
                let fileIds = this.fileIdsDelCzjgfk.toString();
                let fileRes = await this.DeleteFiles({
                    fileIds: fileIds,
                });
                if (fileRes.ISSUCCESS) {
                    this.fileIdsDelCzjgfk = [];
                    this.saveJgfkAct();
                } else {
                    this.common.showMsg("保存失败", "error");
                }
            } else {
                this.saveJgfkAct();
            }
        },
        // 保存处置结果反馈数据
        async saveJgfkAct() {
            let czjgJson = {
                ID: this.czjgfkData.ID,
                YJSJID: this.id,
                FKBM: this.czjgfkData.FKBM,
                FKR: this.czjgfkData.FKR,
                FKNR: this.czjgfkData.FKNR,
                FKSJ: this.czjgfkData.FKSJ,
                SFYCZJGGZ: this.czjgfkData.SFYCZJGGZ, //是否对处置结果进行跟踪
                SFYGZSJ: this.czjgfkData.SFYGZSJ, //是否已有跟踪数据
            };
            let fileids = "";
            if (this.czjgfkData.PIC.length) {
                fileids = this.czjgfkData.PIC[0].FJID;
            }

            let sfygzsj = this.czjgfkData.SFYGZSJ; //是否已有跟踪数据
            let yjczgzjl = null; //预警记录
            let yjgzpz = null; //预警配置
            // 是否已有跟踪数据 有
            if (Number(sfygzsj) === 1) {
                this.gzjlItem.YJSJID = this.id;
                yjczgzjl = this.gzjlItem;
            } else {
                // 没有
                this.taskData.YJSJID = this.id;
                if (!this.taskData.FZRMC) {
                    this.taskData.FZRMC = this.common.aryListFindOne(
                        this.gzfzrList,
                        "ID",
                        this.taskData.FZRID
                    ).REALNAME;
                }
                yjgzpz = this.taskData;
            }
            let czjggzJson = {
                yjczgzjl: null, //有跟踪记录
                yjgzpz: null, //没有跟踪记录
            };
            // 是否对处置结果进行跟踪  SFYCZJGGZ  0否 1 是
            if (Number(czjgJson.SFYCZJGGZ) === 1) {
                czjggzJson = {
                    yjczgzjl: yjczgzjl,
                    yjgzpz: yjgzpz,
                };
            } else {
                czjgJson.SFYCZJGGZ = "0";
            }

            let res = await this.SaveCzjgData({
                czjgJson: czjgJson, // CzjgDto模型
                fileids: fileids, // 处置结果的附件id
                sfygzsj: sfygzsj, // 是否已有跟踪数据;1是
                czjggzJson: czjggzJson, // CzjggzDto模型
            });

            if (res.ISSUCCESS) {
                if (Number(sfygzsj) === 1) {
                    // 预警状态 0未处置 1处置中 2无效预警 3已解除 步骤状态
                    this.$emit("changeTopState", 1, 3);
                } else {
                    let yjgzpzid = res.RESULTDESCRIPTION;
                    // 下发任务
                    let res2 = await this.GetYjxxYjgzTask({
                        yjgzpzid: yjgzpzid,
                    });
                    if (res2.ISSUCCESS) {
                        // 预警状态 0未处置 1处置中 2无效预警 3已解除 步骤状态
                        this.$emit("changeTopState", 1, 3);
                    } else {
                        this.common.showMsg("下发任务失败", "error");
                    }
                }
            } else {
                this.common.showMsg("保存失败", "error");
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.yjczItemPart {
    .configPart {
        .actionBtn {
            width: 100%;
            margin-top: 30px;
            margin-bottom: 20px;
            border-bottom: 1px solid #797979;
            padding: 2px 20px 10px 20px;
        }
        .enterForm {
            width: 90%;
            margin: 0 auto;
            .czjgjlItem {
                width: calc(50% - 20px);
                .label {
                    height: 40px;
                    margin-right: 30px;
                    color: #a4a4a2;
                }
            }
        }
        .czjggzPart {
            width: 90%;
            margin: 0 auto;
            .label {
                height: 40px;
                margin-right: 30px;
            }
        }
        .title {
            margin-bottom: 20px;
            border-bottom: 1px solid #797979;
            padding: 2px 20px;
        }

        .yygzsj-border {
            width: 100%;
            .xzyygzsj-border {
                max-height: 470px;
                overflow-y: auto;
            }
            .yygzsj-item {
                display: inline-block;
                width: calc(50% - 20px);
                margin-bottom: 20px;
                background: #fff;
                .flex-space-between {
                    display: flex;
                    justify-content: space-between;
                }
                .gzsj-header {
                    border-bottom: 1px solid #797979;
                    padding: 5px 20px;
                    .gzsj-head-date {
                        font-weight: bold;
                    }
                }
                .gzsj-body {
                    padding: 10px 20px;
                    .gzsj-text {
                        width: calc(100% - 135px);
                        .gzsj-label {
                            height: 60px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                    .gzsj-img-border {
                        width: 135px;
                        height: 85px;
                    }
                }
            }
            .yygzsj-item:nth-of-type(odd) {
                margin-right: 40px;
            }
            .yygzsj-item:nth-of-type(even) {
                margin-right: 0;
            }
        }
    }
}
</style>
